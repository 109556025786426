<script setup lang="ts">
  import type { ClaimedReward, Reward } from '~/types/reward'

  const { t, te } = useI18n()
  const companyId = parseInt(useRoute().params.companyId as string, 10)

  const { isMobile } = storeToRefs(useStoreApp())
  const { getCreatorBalance } = useStoreCreator()
  const { data: balance } = getCreatorBalance(companyId)
  const { data: creator } = useStoreCreator().fetchCreator()
  const { claimReward } = useStoreReward()
  const { mutateAsync, isPending, error: claimError } = claimReward()
  const footerRef = ref()

  const props = defineProps<{
    reward: Reward
    show: boolean
  }>()

  const emit = defineEmits<{
    close: []
    claim: [ClaimedReward]
  }>()

  onMounted(() => {
    useTrack().track('modal-rewards-details', {
      email: creator.value?.email ?? '',
      reward_type: props.reward.promo_type
    })
  })

  const section = computed(() => {
    const { brandings } = useStoreBranding()

    if (!brandings) return

    const { extractTypography } = useBrandingTypography()
    const { extractButton } = useBrandingButton()

    return {
      card: brandings.branding_reward.card,
      rewardName: extractTypography('branding_reward', 'reward_text', 'primary'),
      rewardPoints: extractTypography('branding_reward', 'point_text', 'secondary'),
      actionButton: extractButton('branding_reward', 'action_btn')
    }
  })

  const canExchange = computed(() => {
    return props.reward.threshold <= (balance.value || 0)
  })

  const onClaim = async () => {
    useTrack().track('click-reward-exchange', {
      email: creator.value!.email,
      reward_type: props.reward.promo_type,
      reward_id: props.reward.id
    })

    const res = await mutateAsync({ companyId: companyId, rewardId: props.reward.id })
    emit('claim', res)
  }

  const shouldTeleportBtnsToFooter = computed(() => {
    return footerRef.value && isMobile.value
  })

  const claimErrorFormatted = ref('')
  const showErrorText = ref(false)

  watch(claimError, (value) => {
    if (value) {
      if (value.statusCode === 422) {
        showErrorText.value = true
      } else {
        const errorMsg = Object.values(value['response']._data as { string: string }).shift()
        const transKey = `ApiErrors.${errorMsg}`
        claimErrorFormatted.value = te(transKey) ? t(transKey) : t('ApiErrors.generic')
      }
    }
  })

  const onReload = () => {
    location.reload()
  }
</script>

<template>
  <ModalApp
    class="reward-detail-modal"
    footer-fixed
    :show
    :no-footer="!isMobile"
    @close="emit('close')"
  >
    <div
      v-if="showErrorText"
      class="reward-error text-secondary-heading-2"
    >
      <p>
        {{ $t('SectionRewardsDetailModal.errorMessage1') }}
        <br />
        <br />
        {{ $t('SectionRewardsDetailModal.errorMessage2') }}
        <br />
        <br />
        {{ $t('SectionRewardsDetailModal.errorMessage3') }}
      </p>
      <ButtonBranding
        v-if="!isMobile"
        class="reward-error--btn"
        :button-branding="section!.actionButton"
        @click="onReload"
      >
        {{ $t('SectionRewardsDetailModal.errorButton') }}
      </ButtonBranding>
    </div>

    <SectionRewardsDetailInfos
      v-else
      :reward="reward"
    >
      <template #btn>
        <ClientOnly>
          <Teleport
            :to="footerRef"
            :disabled="!shouldTeleportBtnsToFooter"
          >
            <div class="reward-detail-modal__btn">
              <ButtonBranding
                :button-branding="section!.actionButton"
                type="submit"
                :loading="isPending"
                :disabled="!canExchange"
                @click="onClaim"
              >
                {{ $t('SectionRewardsDetailModal.exchange') }}
              </ButtonBranding>
              <FormError v-if="claimError">
                {{ claimErrorFormatted }}
              </FormError>
            </div>
          </Teleport>
        </ClientOnly>
      </template>
    </SectionRewardsDetailInfos>
    <template #footer>
      <ButtonBranding
        v-if="showErrorText && isMobile"
        :button-branding="section!.actionButton"
        @click="onReload"
      >
        {{ $t('SectionRewardsDetailModal.errorButton') }}
      </ButtonBranding>
      <div
        ref="footerRef"
        class="reward-detail-modal__footer"
      />
    </template>
  </ModalApp>
</template>

<style scoped>
  .reward-detail-modal {
    &__footer {
      width: 100%;
      text-align: center;

      .lds-form-error {
        margin-top: 5px;
      }
    }
    &__btn {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (min-width: 768px) {
        flex-direction: column;
      }

      @media (min-width: 1024px) {
        flex-direction: row;
      }
    }
  }

  .reward-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 40px;

    &--btn {
      width: 200px;
    }
  }
</style>
